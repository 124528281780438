<template>
<div>
    <div class="abvvvv">
        <ve-line :mark-line="markLine" :grid="{right: '5%'}" :data="incomeData" :extend="{dataZoom:[],yAxis:{splitLine: {
            lineStyle: {
                type: 'dotted'
            }
        }}}" height="500px" :legend="{left: 0,bottom:0}" :settings="{ 
        lineStyle: {
            normal: {
                width: 3
            }
        },
        areaStyle:{
            area: true,
            normal:{
                color:{
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 1, color: '#effffb' // 0% 处的颜色
                    }, {
                        offset: 0, color: '#23d9ae' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
                shadowColor: 'rgba(0, 0, 0, 0.1)',
                shadowBlur: 10
            }
        },
        label: { show: true, position: 'top'}}"></ve-line>

<ve-bar 
    :legend="{show:false}" 
    :grid="{right: '5%'}"
    :title="{text:rank_price_list.title,textAlign:'center',show:false}" :data="rank_price_list" height="1700px" width="100%" 
    :settings="{label: { show: true, position: 'right'},xAxisType: ['KMB', 'KMB']}" :extend="{xAxis:{
        splitLine: {
        lineStyle: {
            type: 'dotted'
        }
        }
    },
    yAxis:{
        inverse: true,  
        splitLine:{show: false},
        axisLabel: {
            color: '#333',
            rich: {
                b: {
                    color: '#f4bc2f',
                    align: 'center',
                }
            },
            formatter: function(params,index) {
                return [
                            `${params} {b| ${index + 1}}`
                    ].join('\n')
            }
        }
    },
    series:{
        barMaxWidth:20,
        margin:80,
        itemStyle:{
            normal:{
                color: function(params) {
                return {
                    type: 'linear',
                    colorStops: [{
                        offset: 0,
                        color: '#effffb'
                        
                    }, {
                        offset: 1,                    
                        color: '#23d9ae'
                    }],
                }
    },
    barBorderRadius:[0,30,30,0]}}},dataZoom:[{type:'slider'}]}"></ve-bar>

    </div>
    
    <div class="abvvvv">
        <baidu-map class="bm-view" v-if="0" center="上海" :zoom="14" :scroll-wheel-zoom="true" @click="onMapClick" @ready="setDistanceToolInstance">
            
        <!-- <baidu-map class="bm-view" :center="{lng: 116.404, lat: 39.915}" :zoom="15" :scroll-wheel-zoom="true"></baidu-map> -->
            <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list>

            
            <!-- <bm-local-search keyword="学" :nearby="{
        center: {
          lng: 121.439082, 
          lat: 31.186803
        },
        radius: 1000
      }" :auto-viewport="true" :panel="false"></bm-local-search>
            <bm-circle :center="{
          lng: 121.439082, 
          lat: 31.186803
        }" radius="1000"></bm-circle> -->
            <bm-overview-map anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :isOpen="true"></bm-overview-map>
            <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
            <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
            <bm-scale anchor="BMAP_ANCHOR_BOTTOM_LEFT"></bm-scale>

            

            <!-- <bm-circle :center="{lng: 121.44022245672527, lat: 31.180750614203674}" radius="500" stroke-color="" :stroke-opacity="0.3" :stroke-weight="2" :editing="false"></bm-circle> -->
            <!-- <bml-heatmap :data="[
        {lng: 121.44022245672527, lat: 31.180750614203674, count: 500},
        {lng: 121.44022245672527, lat: 31.180750614203674, count: 151},
        {lng: 121.4458421378046, lat: 31.19043048266527, count: 250}
      ]" :max="1000" :radius="30"></bml-heatmap> -->

            <!-- <bm-local-search @markersset="searchLocation" :panel="false" :keyword="keyword" :selectFirstResult="true" :auto-viewport="true"></bm-local-search> -->

            <bml-marker-clusterer :averageCenter="true" v-for="(maker_list,index) in bmaker" :key="index">
                <bm-marker v-for="(maker,index) in maker_list" :key="index" :position="maker.position" :dragging="true" :title="maker.studio_name" :icon="maker.icon">
                    <!-- <bm-label :content="maker.studio_name" :labelStyle="{color: 'red', fontSize : '10px'}" :offset="{width: -35, height: 30}"/> -->
                </bm-marker>
                <bm-circle  v-for="(maker,index) in maker_list" :key="index" :position="maker.position" :center="maker.position" radius="500" :fillOpacity="0.1" :stroke-color="maker.border_color" :stroke-opacity="0.5" :stroke-weight="2.5" :editing="false"></bm-circle>
            </bml-marker-clusterer>

            <!-- <bm-boundary name="杭州市" :strokeWeight="8" strokeColor="#FFF" fillColor="" ></bm-boundary> -->
            <bm-boundary name="上海市徐汇区" :strokeWeight="4" strokeColor="blue" fillColor="" ></bm-boundary>
            <bm-boundary name="上海市宝山区" :strokeWeight="4" strokeColor="blue" fillColor="" ></bm-boundary>
            <!-- <bm-boundary name="上海市浦东新区" :strokeWeight="4" strokeColor="#42855B" fillColor="" ></bm-boundary> -->
            <!-- <bm-boundary name="上海市闵行区" :strokeWeight="4" strokeColor="#0F3460" fillColor="" ></bm-boundary> -->
            
            <!-- <bm-boundary name="杭州市下城区" :strokeWeight="4" strokeColor="green" fillColor="" ></bm-boundary>
            <bm-boundary name="杭州市拱墅区" :strokeWeight="4" strokeColor="red" fillColor="" ></bm-boundary>
            <bm-boundary name="杭州市江干区" :strokeWeight="4" strokeColor="yellow" fillColor="" ></bm-boundary>
            <bm-boundary name="杭州市余杭区" :strokeWeight="4" strokeColor="blue" fillColor="" ></bm-boundary>
            <bm-boundary name="杭州市滨江区" :strokeWeight="4" strokeColor="red" fillColor="" ></bm-boundary>
            <bm-boundary name="杭州市萧山区" :strokeWeight="4" strokeColor="green" fillColor="" ></bm-boundary> -->

            <bm-control>
                <a-button size="small" icon="pushpin" style="margin-top:10px;margin-left:80px;" @click="openDistanceTool">开启测距</a-button>
            </bm-control>
            
            
        </baidu-map>
        <!-- <ve-line :data="chartData" style="width:1280px;margin-top: 250px;" :extend="{series: {
        smooth: false
      }}" ></ve-line> -->
      <!-- <heatmap :loading="loading" :item="heatmap" title="BI建模" />  -->
      <!-- <chmap :loading="loading" :item="hourmap" title="上课热点图"/> -->
    </div>

    <div class="abvvvv" v-if="0">
        <ve-ring  
        :legend="{show:false}"  v-for="(item,index) in barChartData" :key="index"
        :title="{
            text: item.title,
            subtext: '总数',
            textStyle: {
                fontSize: 22,
                color: ['#23d9ae']
            },
            subtextStyle: {
                fontSize: 15
            },
            x: 'center',
            y: '33%'
        }"
        :data="item" height="560px" width="900px" 
        :extend="{
            series:{
                label: {
                    normal: {
                        formatter: params => {
                            return (
                                 params.name +' '+ params.percent +'%'
                            );
                        }
                    }
                }
            }
        }" ></ve-ring>
    </div>


  <div class="abvvvv" v-if="0">
    <ve-bar  
    :legend="{show:false}" 
    :grid="{right: '5%'}"
    :title="{text:'123123',textAlign:'center',show:false}" v-for="(item,index) in barChartData" :key="index" :data="item" height="700px" width="900px" 
    :settings="{label: { show: true, position: 'right'},xAxisType: ['KMB', 'KMB']}" :extend="{    
    xAxis:{
        splitLine: {
        lineStyle: {
            type: 'dotted'
        }
        }
    },
      yAxis:{
        inverse: true,  
        splitLine:{show: false},
        axisLabel: {
            color: '#333',
            rich: {
                b: {
                    color: '#f4bc2f',
                    align: 'center',
                }
            },
            formatter: function(params,index) {
                return [
                         `${params} {b| ${index + 1}}`
                    ].join('\n')
            }
        }
    }, series:{
        barMaxWidth:20,
        margin:80,
        itemStyle:{
            normal:{
                color: function(params) {
                return {
                    type: 'linear',
                    colorStops: [{
                        offset: 0,
                        color: '#effffb'
                        
                    }, {
                        offset: 1,                    
                        color: '#23d9ae'
                    }],
                }
        },barBorderRadius:[0,30,30,0]}}},dataZoom:[{type:'slider'}]}" ></ve-bar>
  </div>


  <div class="abvvvv"  v-if="0">
    <ve-bar 
    :legend="{show:false}" 
    :grid="{right: '5%'}"
    :title="{text:'123123',textAlign:'center',show:false}" v-for="(item,index) in barRankChartData" :key="index" :data="item" height="1300px" width="900px" 
    :settings="{label: { show: true, position: 'right'},xAxisType: ['KMB', 'KMB']}" :extend="{    
    xAxis:{
        splitLine: {
        lineStyle: {
            type: 'dotted'
        }
        }
    },
      yAxis:{
        inverse: true,  
        splitLine:{show: false},
        axisLabel: {
            color: '#333',
            rich: {
                b: {
                    color: '#f4bc2f',
                    align: 'center',
                }
            },
            formatter: function(params,index) {
                return [
                         `${params} {b| ${index + 1}}`
                    ].join('\n')
            }
        }
    }, series:{
        barMaxWidth:20,
        margin:80,
        itemStyle:{
            normal:{
                color: function(params) {
                return {
                    type: 'linear',
                    colorStops: [{
                        offset: 0,
                        color: '#effffb'
                        
                    }, {
                        offset: 1,                    
                        color: '#23d9ae'
                    }],
                }
        },barBorderRadius:[0,30,30,0]}}},dataZoom:[{type:'slider'}]}" ></ve-bar>
  </div>


    <div style="background:#fff;"  v-if="0">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="small" :pagination="false" :bordered='false' 
        :columns="columns" :dataSource="date_income_list" :scroll="{y:500}" style="width:90%">
            <template slot="name" slot-scope="text">
                <a-tooltip>
                <template slot="title">
                    <p>{{text}}</p>
                </template>
                <div class="table-name">{{text}}</div>
                </a-tooltip>
            </template>
        </a-table>
    </div>


    <div style="background:#fff;" v-if="0">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="small" :pagination="false" :bordered='false' 
        :columns="columns" :dataSource="date_count_list" :scroll="{ x: RelativeWidth, y:500}" style="width:90%">
            <template slot="name" slot-scope="text">
                <a-tooltip>
                <template slot="title">
                    <p>{{text}}</p>
                </template>
                <div class="table-name">{{text}}</div>
                </a-tooltip>
            </template>
        </a-table>
    </div>

  <div class="abvvvv" v-if="0">
      <ve-line :mark-line="markLine" :grid="{right: '5%'}" :data="incomeCountChartData" :extend="{dataZoom:[],yAxis:{splitLine: {
            lineStyle: {
                type: 'dotted'
            }
        }}}" height="500px" width="900px"  :legend="{left: 0,bottom:0}" :settings="{ 
        lineStyle: {
            normal: {
                width: 3
            }
        },
        areaStyle:{
            area: true,
            normal:{
                color:{
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 1, color: '#effffb' // 0% 处的颜色
                    }, {
                        offset: 0, color: '#23d9ae' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
                shadowColor: 'rgba(0, 0, 0, 0.1)',
                shadowBlur: 10
            }
        },
        label: { show: true, position: 'top'}}"></ve-line>

        <ve-histogram  :legend="{show:false}" :data="incomeData" :mark-line="markLine" :grid="{right: '5%'}" height="500px" width="900px" :settings="{yAxisType: ['KMB', 'KMB'],label: { show: true, position: 'top'}}" 
        :extend="{

        yAxis:{
            splitLine: {
            lineStyle: {
                type: 'dotted'
            }
            }
        },

        series:{
            type:'bar',
            barMaxWidth:10,
            itemStyle:{
                normal:{
                    color: function(params) {
                        return {
                            type: 'linear',
                            x:1,
                            y:1,
                            x2:0,
                            y2:0,
                            colorStops: [{
                                offset: 0,
                                color: '#effffb'
                                
                            }, {
                                offset: 1,                    
                                color: '#23d9ae'
                            }],
                        }
                    },
                    barBorderRadius:[30,30,0,0]
                }
            }
      },
      dataZoom:[]}" ></ve-histogram>
  </div>

  <div class="abvvvv"  v-if="0">
    <ve-line v-for="(item,index) in chartDatas" :key="index" :data="item" :extend="{dataZoom:[],yAxis:{splitLine: {
            lineStyle: {
                type: 'dotted'
            }
        }}}" height="260px" width="300px" :legend="{left: 0,bottom:0}" :settings="{ 
        yAxisType: ['KMB', 'KMB'],
        lineStyle: {
            normal: {
                width: 1
            }
        },
        areaStyle:{
            area: true,
            normal:{
                color:{
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 1, color: '#effffb' // 0% 处的颜色
                    }, {
                        offset: 0, color: '#23d9ae' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
                shadowColor: 'rgba(0, 0, 0, 0.1)',
                shadowBlur: 10
            }
        },
        label: { show: true, position: 'top'}}"></ve-line>
  </div>

  <div class="abvvvv">
    <ve-line v-for="(item,index) in dailyChart" :key="index" :data="item" :extend="{dataZoom:[],yAxis:{
            splitLine: {
            lineStyle: {
                type: 'dotted'
            }
            }
        },}" height="260px" width="300px" :legend="{left: 0,bottom:0}" :settings="{ 
        yAxisType: ['KMB', 'KMB'],
        lineStyle: {
            normal: {
                width: 1
            }
        },
        areaStyle:{
            area: true,
            normal:{
                color:{
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 1, color: '#effffb' // 0% 处的颜色
                    }, {
                        offset: 0, color: '#23d9ae' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
                shadowColor: 'rgba(0, 0, 0, 0.1)',
                shadowBlur: 10
            }
        },
        label: { show: true, position: 'top'}}"></ve-line>
  </div>
</div>
</template>
<script>
import heatmap from './heatmap'
import chmap from './chmap'
import QRCode from 'qrcodejs2'
import DistanceTool from 'bmaplib.distancetool'
import {BmlMarkerClusterer,BmlHeatmap} from 'vue-baidu-map'

  export default {
    name:'ContractExport',
    data () {
        this.visualMap = [
        {
          type: 'piecewise',
          splitNumber: 5,
          min: 0,
          max: 60,
          right: 0,
          top: '50%'
        }
      ]
      this.markLine = {
        symbol: ['none', 'none'], //去掉箭头
        data: [
          {
            name: '平均线',
            type: 'average'
          }
        ]
      }
      this.chartSettings = {
        xAxisType: ['KMB', 'KMB'],
      }
      return {
        incomeCountChartData:[],
        barRankChartData:[],
        incomeData: [],
        chartDatas:[],
        rank_price_list:[],
        chartData:{
        },
        keyword:'',
        searchList: [],
        bmcenter:{lng: 121.439082, lat: 31.186803},
        bmaker:[],
        heatmap:{},
        heatdata:[
            {lng: 121.44022245672527, lat: 31.180750614203674, count: 500},
            {lng: 121.44022245672527, lat: 31.180750614203674, count: 51},
            {lng: 121.4458421378046 , lat: 31.19043048266527 , count: 15}
        ],
        hourmap:{},
        barChartData:[],
        dailyChart:[],
        columns:[],
        BMap:{},
        date_income_list:[],
        date_count_list:[],
        current: 2,
        loading:false,
        locationKeyword:'',
        pageParams:{
          currentPage: 1,
          pageCount: 50,
          perPage: 10,
          totalCount: 492
        },
      }
    },
    mounted(){
      this.getList();
    },
    unmount () {
        distanceTool && distanceTool.close()
    },
    components: {
        chmap,
        heatmap,
        BmlHeatmap,
        BmlMarkerClusterer
    },
    computed: {
        RelativeWidth() {
            let width = 850
            if(this.filedLength >10){
              width = 900 + ( this.filedLength - 10 ) * 130
            }
            return width
        }
    },
    methods:{
        addZoom (level) {
            console.log(level)
        },
        setDistanceToolInstance ({map}) {
            this.distanceTool = new DistanceTool(map, {lineStroke : 2})
            console.log(this.distanceTool)
        },
        openDistanceTool (e) {
            const {distanceTool} = this
            distanceTool && distanceTool.open()
        },
        handler({ BMap }) {
            this.BMap = BMap
            // console.log(BMap)
        },
        onMapClick(type) {
            const point = { ...type.point }
            this.longitude = point.lng
            this.latitude = point.lat
            const geocoder = new this.BMap.Geocoder()
            console.log(point)
            console.log(new this.BMap.Point(point.lng, point.lat))
            geocoder.getLocation(new this.BMap.Point(point.lng, point.lat), (geocoderResult) => {
                if (geocoderResult) {
                    const address = geocoderResult.address
                    this.locationKeyword = address
                    console.log(this.locationKeyword);
                }
            })
        },
        getClickInfo(e){
            // this.bmcenter.lat = e.point.lat
            // this.bmcenter.lng = e.point.lng
            console.log(e.point);
        },
        async getList() {
            this.loading = true
            // let dailyCompare = await this.$store.dispatch('analysisAceCompareAction', {})
            // // this.chartData = dailyCompare.data.kamal_map;
            // this.heatmap = dailyCompare.data.heatmap;
            // this.hourmap = dailyCompare.data.hourmap;
            // this.bmaker = dailyCompare.data.location_map;
            // console.log(this.Rsa.decrypt(Base64.decode());
            // 
            // console.log(Base64.decode(dailyCompare.data.location_rsa));
            // console.log(this.Rsa.rsaPrivateData(Base64.decode(dailyCompare.data.location_rsa) ));

            // let res = await this.$store.dispatch('analysisAceAction', {})
            // console.log(res);
            // this.chartDatas = res.data;

            let income = await this.$store.dispatch('analysisAceIncomeAction', {})
            // console.log(income);
            this.incomeData=income.data;

            // let resss = await this.$store.dispatch('analysisAceBarAction', {})
            // console.log(resss);
            // this.barChartData = resss.data;            

            // let ressss = await this.$store.dispatch('analysisAceRankAction', {})
            // this.barRankChartData = ressss.data;            

            // let incomeCount = await this.$store.dispatch('analysisAceIncomeCountAction', {})
            // this.incomeCountChartData = incomeCount.data;            


            this.loading=true;
            let dailyCount = await this.$store.dispatch('analysisAceDailyAction', {})
            this.rank_price_list = dailyCount.data.rank_price_list
            console.log(this.rank_price_list)
            // this.dailyChart = dailyCount.data;            

            let list = dailyCount.data.date_count_list.rows
            let week = [
                { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index', fixed: 'left'},
                { title: '名称',  dataIndex: 'name',  key: 'name', scopedSlots: { customRender: 'name' }, fixed: 'left'},
            ]
            if(list[0]){
              list[0].filed.forEach(item=>{
                  let obj = { title: item.filed_name, dataIndex: item.filed_name, key: item.filed_name,align:'right',width:'60px'}
                  week.push(obj)
              })
            }
            let count_list = dailyCount.data.date_count_list.rows
            count_list.forEach(item=>{
                item.filed.forEach(it=>{
                    item[it.filed_name] = it.filed_value;
                })
            })


            let income_list = dailyCount.data.date_income_list.rows
            income_list.forEach(item=>{
                item.filed.forEach(it=>{
                    item[it.filed_name] = it.filed_value;
                })
            })

            this.columns=week;
            this.date_income_list=income_list;
            this.date_count_list=count_list;
            this.loading=false;
        },
        qrcode () {
            var that = this;
            this.$nextTick(() => {
                var canvas = this.$refs.qrcode
                let img = new QRCode(canvas, {
                    text:'www.baidu.com', 
                    width:100,
                    height:100,
                    colorDark : "#000",   //二维码颜色
                    colorLight : "#ffffff"   //二维码背景色
                })
            })
        },
    },
  }
</script>
<!-- <style lang="scss">
  .abvvvv{
    display: flex;
    flex-wrap: wrap;
  }
</style> -->
<style lang="scss">
    .bm-view {
        width: 100%;
        height: 1080px;
    }
  .staffTaget{
    .ant-table-row{
      td:nth-child(2){
        background: rgb(229, 244, 254);
      }
      td:nth-child(3){
        background: rgb(229, 244, 254);
      }
      td:nth-child(4){
        background: rgb(229, 244, 254);
      }
      td:nth-child(5){
        background: rgb(229, 244, 254);
      }
    }
    .table-name{
      width: 140px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
</style>