var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{attrs:{"title":""}},[_c('div',[_c('vue-element-loading',{attrs:{"active":_vm.loading,"color":"#00cca2","spinner":"spinner"}}),_c('ve-heatmap',{attrs:{"data":_vm.item,"judge-width":"","title":{
                text:("" + _vm.title),
                textStyle: {
                    fontSize: 15,
                },
                subtextStyle: {
                    fontSize: 12.5
                }
            },"extend":{
              series:{
                itemStyle:{
                  borderColor:'#fff',
                  borderWidth:1
                }
              }
            },"settings":_vm.chartSettings,"visual-map":_vm.chartVisualMap,"grid":_vm.chartGrid}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }